import React from 'react'

class NotFound extends React.Component {
  componentDidMount() {
    window.location = '/'
  }

  render() {
    return null
  }
}

export default NotFound
